import React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

const styles = {className:"px-4 py-2 text-sm font-bold text-white bg-gray-700 border-b-4 border-gray-800 rounded hover:border-gray-700 hover:bg-gray-600"};
const A = styled.a.attrs(styles)``;
const Button = styled.button.attrs(styles)``;

function ContactPage(props) {
  return (
    <Layout {...props}>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Contact"
      />
      <section className="grid grid-cols-2 gap-8">
        <div>
          <h2 className="text-4xl pb-8">Contact</h2>
          <form action="https://formspree.io/f/xbjwdgqw" method="POST">
            <p className="pb-4">Heeft u een vraag? Dan kunt u het formulier hieronvder invullen, dan mailen we u zo snel mogelijk terug.</p>
            <input
              className="w-full mb-4 rounded-md border-gray-800"
              id="name"
              name="name"
              type="text"
              placeholder="Naam"
            />
            <input
              className="w-full mb-4 rounded-md border-gray-800"
              id="email"
              name="_replyto"
              type="email"
              placeholder="Email"
            />
            <input
              className="w-full mb-4 rounded-md border-gray-800"
              id="phonenumber"
              name="phonenumber"
              type="text"
              placeholder="Telefoon nummer"
            />
            <textarea
              className="w-full mb-4 rounded-md border-gray-800"
              id="message"
              name="message"
              rows="8"
              placeholder="Bericht"
            />
            <Button className="px-4 py-2 text-sm font-bold text-white bg-gray-700 border-b-4 border-gray-800 rounded hover:border-gray-700 hover:bg-gray-600" type="submit">
              Verstuur
            </Button>
          </form>
        </div>
        <div>
          <h2 className="text-4xl pb-8">Adres</h2>
          <p className="mb-4">Voetsalon Akkrum<br />Tel: 0566-785163 <br />Kanadeeskestrjitte 53<br />8491 BC, Akkrum</p>
          <A href="/locatie" className="">Bekijk op kaart »</A>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
